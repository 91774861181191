import { render, staticRenderFns } from "./Ic5gBsfView.vue?vue&type=template&id=0c009996&scoped=true"
import script from "./Ic5gBsfView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic5gBsfView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c009996",
  null
  
)

export default component.exports